export enum EHomeworkTopicsAction {
  GET_HOMEWORK_TOPICS = 'GET_HOMEWORK_TOPICS',
  LOAD_MORE_HOMEWORK_TOPICS = 'LOAD_MORE_HOMEWORK_TOPICS',
  CREATE_HOMEWORK_TOPIC = 'CREATE_HOMEWORK_TOPIC',
  CREATE_HOMEWORK = 'CREATE_HOMEWORK',
  UPDATE_HOMEWORK_BY_ID = 'UPDATE_HOMEWORK_BY_ID',

  GET_HOMEWORK_TOPICS_BY_TYPE = 'GET_HOMEWORK_TOPICS_BY_TYPE',
  LOAD_MORE_HOMEWORK_TOPICS_BY_TYPE = 'LOAD_MORE_HOMEWORK_TOPICS_BY_TYPE',

  ASSIGN_HOMEWORK = 'ASSIGN_HOMEWORK',

  GET_TASKS_BY_TOPIC = 'GET_TASKS_BY_TOPIC',
  LOAD_MORE_TASKS_BY_TOPIC = 'LOAD_MORE_TASKS_BY_TOPIC',
}
