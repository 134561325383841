export enum EPractitionersAction {
  GET_PRACTITIONERS = 'GET_PRACTITIONERS',
  INVITE_PRACTITIONER = 'INVITE_PRACTITIONER',
  UPDATE_PRACTITIONER_PROFILE = 'UPDATE_PRACTITIONER_PROFILE',
  GET_DETAIL_STATISTIC = 'GET_DETAIL_STATISTIC',
  RESEND_INVITATION_PRACTITIONER = 'RESEND_INVITATION_PRACTITIONER',
  REVOKE_INVITATION_PRACTITIONER = 'REVOKE_INVITATION_PRACTITIONER',
  REACTIVE_PRACTITIONER = 'REACTIVE_PRACTITIONER',
  DEACTIVE_PRACTITIONER = 'DEACTIVE_PRACTITIONER',
  GET_LITE_PRACTITIONERS = 'GET_LITE_PRACTITIONERS',
  OWNER_SUMMARY_ASSIGN_TASK = 'OWNER_SUMMARY_ASSIGN_TASK',
  PRACTITIONER_SUMMARY_ASSIGN_TASK = 'PRACTITIONER_OWNER_SUMMARY_ASSIGN_TASK',
  PRACTITIONER_CLIENT_LOGIN_RATE = 'PRACTITIONER_CLIENT_LOGIN_RATE',
  OWNER_CLIENT_LOGIN_RATE = 'OWNER_CLIENT_LOGIN_RATE',
  PRACTITIONER_ENGAGEMENT = 'PRACTITIONER_ENGAGEMENT',
  TASK_COMPLETE_TASK = 'TASK_COMPLETE_TASK',
}
