export enum EChatActions {
  GET_CHAT_MEMBERS = 'GET_CHAT_MEMBERS',
  LOAD_MORE_CHAT_MEMBERS = 'LOAD_MORE_CHAT_MEMBERS',
  START_ROOM = 'START_ROOM',
  GET_ROOMS = 'GET_ROOMS',
  LOAD_MORE_ROOMS = 'LOAD_MORE_ROOMS',
  LEAVE_ROOM = 'LEAVE_ROOM',
  SEND_MESSAGE = 'SEND_MESSAGE',
  GET_ROOM_MESSAGES = 'GET_ROOM_MESSAGES',
  LOAD_MORE_ROOM_MESSAGES = 'LOAD_MORE_ROOM_MESSAGES',
  MARK_READ_MESSAGE = 'MARK_READ_MESSAGE',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DELETE_CHAT_ROOM = 'DELETE_CHAT_ROOM',
}
